html {
    height: auto !important;
}

.cursor-pointer {
    cursor: pointer;
}

.back-button:hover {
    color: #3ab53a !important;
}

#background {
    top: 50px;
    bottom: 20px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 0;
    position: fixed;
    height: calc(100vh - 50px);
}

#googlemaps {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -1;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

#map-controls {
    z-index: 0;
    margin-top: 20px;
}

#map-controls .card {
    opacity: .9;
}

.main-nav {
    z-index: 2;
}

.main-nav .navbar-collapse {
    margin: 0 -0.75rem;
}

.main-nav .navbar-collapse li.active {
    background-color: #3ab53a;
}

.main-nav .navbar-collapse a {
}

.main-nav .navbar-collapse li.active a {
    color: #fff !important;
}

.main-nav-link {
    font-size: 20px;
    font-weight: 800;
    border-bottom: 3px solid transparent;
}

.auth-buttons {
    border-bottom: 3px solid transparent;
}

.dropdown-item:hover,
.dropdown-item:focus,
.main-nav-link.active,
.auth-buttons.active {
    background-color: #fff;
    color: #3ab53a !important;
    border-bottom: 3px solid #2e8e2e;
}

.auth-background {
    height: 100%;
    position: relative;
}

.auth-background:before {
    content: "";
    background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("../../public/images/login.jpeg");  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("../../public/images/login.jpeg"); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: fixed;
    left: 0; right: 0;
    top: 0; bottom: 0;
    height: 100%;
}

.marker-image {
    max-height: 240px;
    object-fit: contain;
}

.vueperslide {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.vueperslides__parallax-wrapper {
    height: 400px;
}

.vueperslides__bullet, .vueperslides__bullets button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transition: opacity .6s ease;
    opacity: .5;
}

.vueperslides__bullet.vueperslides__bullet--active,
.vueperslides__bullet.vueperslides__bullet--active button {
    opacity: 1;
}

.footer {
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
}

.mapouter {
    width: 100%;
    height: calc(100% - 115px);
    left: 0;
    right: 0;
    bottom: 0;
    top: 115px;
}

#gmap_canvas,
.gmap_canvas {
    width: 100%;
    height: 100%;
}

#footerMenu {
    left: 0;
    right: 0;
    bottom: 20px;
    height: 50px;
    padding: 0;
    z-index: 2;
}

#footer.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

#footer {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
}

#moreInfo,
#footer a {
    font-size: 22px;
}

.back-button,
.close-button {
    font-size: 26px;
    cursor: pointer;
}

.modal-backdrop {
    z-index: 19;
}

.latest-report:hover {
    background-color: #f5f5f5;
}

ul.pagination {
    z-index: 2;
}

.v-input {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 0;
    height: 100%;
    border: none;
}

.v-select__slot input::placeholder {
    font-size: 0.9rem;
}

.v-menu {
    position: absolute;
    z-index: 2;
    background: #fff;
    transform: none;
    font-size: 0.9rem;
}

.v-application.color-henco-red .v-input {
    border-color: #e42313 !important;
}

.v-select__slot {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    background-color: #f8fafc;
    margin-bottom: 0.5rem;
    height: 37px;
    font-size: 0.9rem;
}

.v-select__slot .v-icon.v-icon--link {
    border: none;
    background: transparent;
}

.v-select__slot input {
    border: none;
    outline: none;
    width: 100%;
    background-color: #f8fafc;
    color: #000;
}

.v-input__control {
    background: #fff;
    z-index: 3;
    width: 100%;
}

.v-autocomplete__content {
    height: 200px;
    overflow: auto;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 10px;
}

.v-autocomplete__content .v-list-item {
    margin-bottom: 10px;
    cursor: pointer;
}

.v-select__slot {
    position: relative;
    align-items: center;
    display: flex;
    max-width: 100%;
    min-width: 0;
    width: 100%;
}

.border-danger .v-select__slot {
    border: 1px solid red;
}

.v-select.v-select--chips .v-select__selections {
    min-height: 35px;
}

.theme--light.v-chip:not(.v-chip--active) {
    background: #e0e0e0;
}

.v-select__selections {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    line-height: 18px;
    max-width: 100%;
    min-width: 0;
}

.v-chip .v-chip__content {
    align-items: center;
    display: inline-flex;
    height: 100%;
    max-width: 100%;
}

.v-chip {
    align-items: center;
    cursor: default;
    display: inline-flex;
    line-height: 20px;
    max-width: 100%;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-decoration: none;
    transition-duration: .28s;
    transition-property: box-shadow, opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    vertical-align: middle;
    white-space: nowrap;
}

.v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 32px;
    margin: 4px;
}

.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
    margin-right: -4px;
}

.blueimp-gallery > .close {
    top: 65px !important;
}

.word-break {
    word-break: break-all;
}

.sort-icon-up {
    vertical-align: bottom;
}

.sort-icon-down {
    vertical-align: top;
}

.social-icons a,
.social-icons button {
    margin: 0 5px;
}

.social-icons a i,
.social-icons button i {
    font-size: 1.8rem;
    vertical-align: middle;
}

.social-icons a.btn-apple,
.social-icons button.btn-apple {
    background-color: #fff;
    border: 1px solid #000;
    padding: .5rem 2.5rem;
}

.social-icons a.btn-google,
.social-icons button.btn-google {
    background-color: #ec4236;
    border: 1px solid #ec4236;
    padding: .5rem 2.5rem;
}

.social-icons button.btn-facebook {
    background-color: #316bb7;
    border: 1px solid #316bb7;
    padding: .5rem 2.5rem;
}

.social-icons button.btn-twitter {
    background-color: #52c3fa;
    border: 1px solid #52c3fa;
    padding: .5rem 2.5rem;
}

@media only screen and (max-width: 500px) {
    .social-icons {
        flex-wrap: wrap;
    }

    .social-icons button {
        flex: 0 0 45%;
        margin-bottom: 10px;
    }
}

.middle-border {
    overflow: hidden;
    text-align: center;
}

.middle-border:before,
.middle-border:after {
    background-color: #3ab53a;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.middle-border:before {
    right: 0.5em;
    margin-left: -50%;
}

.middle-border:after {
    left: 0.5em;
    margin-right: -50%;
}
