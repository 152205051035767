@media only screen and (max-width: 1920px) {
    .auth-background:before,
    .auth-background {
        height: 100%;
    }
}

@media only screen and (max-width: 1600px) {
    #map-controls {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 1024px) {
    .mapouter {
        height: calc(100% - 60px);
        top: 60px;
    }
}

@media only screen and (max-width: 1024px) {
    #nav li {
        width: 33%;
    }

    .main-nav-link {
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .v-input {
        width: 100%;
    }
}
