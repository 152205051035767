@use 'sass:map';

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Kanit', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$theme-colors: (
    "success": #3ab53a,
    "primary": #0074d9,
    "danger": #ff4136,
    "secondary": #6c757d,
    "warning": #fcf8e3,
    "light": #f8f9fa,
    "white": #fff,
    "info": #17a2b8,
    "dark": #343a40,
    "muted": #6c757d,
);

$pagination-color: map.get($theme-colors, 'success');
$pagination-hover-color: map.get($theme-colors, 'success');
$pagination-border-color: map.get($theme-colors, 'success');
$pagination-focus-color: map.get($theme-colors, 'success');
$pagination-active-border-color: map.get($theme-colors, 'success');
$pagination-hover-border-color: map.get($theme-colors, 'success');
$pagination-active-bg: map.get($theme-colors, 'success');
